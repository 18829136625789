<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-form ref="form" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="押金状态" prop="isForceUpdate">
              <el-select
                v-model="queryInfo.condition.status"
                placeholder="请选择"
                clearable
              >
                <el-option label="未支付" value="0"> </el-option>
                <el-option label="已支付" value="1"> </el-option>
                <el-option label="已退款" value="2"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="锁编号" prop="topic">
              <el-input
                v-model="queryInfo.condition.deviceNum"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="手机号" prop="topic">
              <el-input
                v-model="queryInfo.condition.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" align="right">
            <el-button type="primary" plain @click="reset">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 用户列表区域 -->
      <div :style="{ height: height + 'px' }" style=" margin-top: 20px;">
        <el-scrollbar style="height: 100%; width: 100%">
          <el-row style="width: 100%">
            <el-table
              :data="tableData"
              border
              fit
              highlight-current-row
              style="width: 100%"
              @sort-change="sortChange"
            >
              <!-- <el-table-column label="" align="center" prop="">
                <template slot-scope="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="押金编码" align="center" prop="押金编码">
                <template slot-scope="scope">
                  <span>{{ scope.row.guaranteeCode }}</span>
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="用户名称" align="center" prop="用户名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.memberCode }}</span>
                </template>
              </el-table-column> -->
              <el-table-column label="用户昵称" align="center" prop="用户编码">
                <template slot-scope="scope">
                  <span>{{ scope.row.memberName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="电话" align="center" prop="电话">
                <template slot-scope="scope">
                  <span>{{ scope.row.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="押金" align="center" prop="押金">
                <template slot-scope="scope">
                  <span>{{ scope.row.guaranteeMoney }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column label="退款" align="center" prop="退款">
                <template slot-scope="scope">
                  <span>{{ scope.row.refoundMoney }}</span>
                </template>
              </el-table-column> -->
              <el-table-column label="支付时间" align="center" prop="支付时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.paymentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="退款时间" align="center" prop="退款时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.refundDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="退款金额"
                align="center"
                prop="refoundMoney"
              >
              </el-table-column>
              <el-table-column label="状态" align="center" prop="状态">
                <template slot-scope="scope">
                  <el-tag type="info" v-if="scope.row.status == 0"
                    >未支付</el-tag
                  >
                  <el-tag v-if="scope.row.status == 1" type="success"
                    >已支付</el-tag
                  >
                  <el-tag v-if="scope.row.status == 2" type="warning"
                    >已退款</el-tag
                  >
                  <!-- <span>{{ scope.row.status }}</span> -->
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="center" prop="创建时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.createDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    @click="toRefund(scope.row.guaranteeCode)"
                    >退款</el-button
                  >
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    @click="showPartial(scope.row)"
                    >部分退款</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row></el-scrollbar
        >
      </div>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog title="部分退款" :visible.sync="partialVisible" width="500px">
      <el-row>
        <el-form
          :model="partialRefundForm"
          label-width="100px"
          :rules="partialRefundRules"
          ref="partialRefund"
        >
          <el-col>
            <el-form-item label="退款金额：" prop="refoundMoney">
              <el-input
                v-model="partialRefundForm.refoundMoney"
                type="number"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="partialVisible = false">取 消</el-button>
        <el-button type="primary" @click="toPartialRefund()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          phone: ""
        }
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: ""
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      partialVisible: false,
      guaranteeMoney: 0,
      partialRefundForm: {
        refoundMoney: "",
        guaranteeCode: ""
      },
      partialRefundRules: {
        refoundMoney: [
          { required: true, message: "请输入退款金额", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //退款
    toRefund(code) {
      this.$confirm("确定退此用户押金？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .post("/pay/wechatRefundOrder", {
            guaranteeCode: code
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("退款成功");
            }
            this.getListData();
          });
      });
    },
    showPartial(row) {
      this.partialVisible = true;
      this.guaranteeMoney = row.guaranteeMoney;
      this.partialRefundForm.guaranteeCode = row.guaranteeCode;
    },
    toPartialRefund() {
      let refoundMoney = this.partialRefundForm.refoundMoney;
      if (refoundMoney > this.guaranteeMoney) {
        this.$message.warning("退款金额不能大于押金金额");
        return;
      } else if (refoundMoney == 0) {
        this.$message.warning("退款金额不能为0");
        return;
      }

      this.$http
        .post("pay/wechatRefundOrder", this.partialRefundForm)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("退款成功！");
            this.partialVisible = false;
            this.getListData();
          }
        });
    },
    //条件查询
    search() {
      // this.queryInfo.condition.id = this.searchInput;
      this.getListData();
    },
    reset() {
      this.queryInfo.condition = {};
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/memberGuarantee/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/memberGuarantee/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/memberGuarantee/list", that.queryInfo)
        .then(function(response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 276;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
/deep/.el-scrollbar__view {
  min-width: 100%;
}
/deep/.el-scrollbar__wrap {
  overflow-x: auto;
  height: calc(100%); //多出来的20px是横向滚动条默认的样式
  width: 100%;
}
/deep/.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
  display: inline-block;
}
</style>
